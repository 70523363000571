import loader from '../helpers/loader';

export default {
  async getTopics(fromDate)
  {
    return await loader.getJSON(`/api/messages/topics?fromDate=${fromDate}`);
  },

  async getTopic(baseMessageId)
  {
    return await loader.getJSON(`/api/messages/topics/${baseMessageId}`);
  },

  async getMessages(baseMessageId, minId = 0)
  {
    return await loader.getJSON(`/api/messages/${baseMessageId}?to=${minId}`);
  },

  async getAllMessages({ minId = 0, userId = null })
  {
    let url = `/api/messages?to=${minId}`;
    if (userId) {
      url = `${url}&userId=${userId}`
    }
    return await loader.getJSON(url);
  },

  async send({ subject, content, receiver, attachments, type })
  {
    return await loader.postJSON(`/api/messages`, { subject, content, receiver, attachments, type });
  },

  async markSeenMessages(data)
  {
    return await loader.postJSON(`/api/messages/mark-seen`, { data });
  },

  async getAdminUnseenCount()
  {
    return await loader.getJSON(`/api/messages/admin-unseen-count`);
  },

  async getUnseenCount()
  {
    return await loader.getJSON(`/api/messages/unseen-count`);
  },

  async setTopicTag(messageId, userId, tagId)
  {
    return await loader.postJSON(`/api/messages/topics/${messageId}/tag`, { tagId, userId });
  },

  async getCommentsCount(entity, id)
  {
    return await loader.getJSON(`/api/comments/count/${entity}/${id}`);
  },

  async getComments(entity, id, minId = 0)
  {
    return await loader.getJSON(`/api/comments/${entity}/${id}?to=${minId}`);
  },

  async sendComment({ content, entityType, entityId, attachments })
  {
    return await loader.postJSON(`/api/comments`, { content, entityType, entityId, attachments });
  },

  async getLastMessage(dialogId) {
    return await loader.getJSON(`/api/messages/${dialogId}/last-message`);
  },

  async changeActiveStatus(dialogId, status) {
    return await loader.postJSON(`/api/messages/${dialogId}/is-active`, { status });
  }
}
