<template>
  <div>
    <div class="chat-container">
      <div class="messages-wrapper">
        <div v-if="messages.length > 0"
             class="messages"
             :key="messages[0].dialogId"
             @click="markSeen">
          <infinite-loading @infinite="onInfinite" direction="top" :identifier="currentUser.id">
            <div slot="no-more">{{ $t('messages.noMore') }}</div>
            <div slot="no-results">{{ $t('messages.noResults') }}</div>
          </infinite-loading>
          <div v-for="m in messages"
               :class="getMessageStyle(m)">
            <div class="sender">{{ getSenderName(m) }}</div>
            <div v-for="att in m.attachments">
              <a v-if="att.data" :href="att.data" target="_blank" class="underlined">
                <img v-if="att.data.startsWith('data:image')" :src="att.data" :alt="att.name" class="img" style="width: 100%; max-width: 512px;">
                <span v-else>{{ att.name }}</span>
              </a>
              <a v-else :href="att.path" target="_blank" class="underlined">{{ att.name }}</a>
            </div>
            <div v-html="render(m.content)"></div>
            <div class="time">{{ getMsgDate(m) }}</div>
          </div>
        </div>
        <div v-else class="alert alert-info">
          {{ $t('messages.empty') }}
        </div>
        <div>
          <span v-for="f in filesToAdd">{{ f.name }} <a href="#" @click.prevent="removeAttach(f)">&times;></a></span>
        </div>
        <div class="controls">
          <button class="invisible-btn" @click="upload()"><i class="fa fa-upload"></i></button>
          <textarea v-model="myMessage"
                    :readonly="isSending"
                    class="form-control"
                    :placeholder="$t('messages.write')"
                    @keyup.enter="send"
                    @click="markSeen"
                    @input="markSeen"/>
          <button class="invisible-btn" @click="send()">
            <i class="fa fa-send"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import _ from 'lodash';
import moment from 'moment';
import messages from '../../models/messages';
import socket from '../../models/socket';
import user from '../../models/user';
import fileLoader from '../../models/file-loader';
import textProcessor from '../../models/text-processor';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'MessagesRoute',
  components: {
    InfiniteLoading
  },
  data() {
    return {
      messages: [],
      currentUser: user.getCurrentUser(),
      myMessage: '',
      isSending: false,
      filesToAdd: [],
    }
  },
  methods: {
    getMsgBodyPreview(msg) {
      return _.truncate(msg.content, {length: 30});
    },
    getMsgDate(msg) {
      const mDate = moment(msg.dateCreated);
      if (mDate.isSame(new Date, 'day'))
        return mDate.format('LT');
      else
        return mDate.format('LLL');
    },
    scrollToBottom() {
      const el = this.$el.querySelector('.messages');
      if (el)
        el.scrollTop = el.scrollHeight;
    },
    render(text) {
      if (text === null || text === undefined)
        text = '';

      return textProcessor.render(text.toString());
    },
    getMessageStyle(message) {
      let styles = ['message'];
      if (message.userId === this.currentUser.id) {
        styles.push('bg-info', 'my');
      } else if (message.isViewed) {
        styles.push('bg-success');
      } else {
        styles.push('bg-danger', 'text-white');
      }

      return styles;
    },
    getSenderName(message) {
      if (message.userId === this.currentUser.id) {
        return this.$t('messages.senderParticipant');
      } else {
        return this.$t('messages.senderAdmin');
      }
    },
    async upload() {
      const files = await fileLoader({
        allowedExtensions: ['jpg', 'jpeg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'txt'],
        maxSize: 16 * 1024 * 1024
      });
      this.filesToAdd.push(...files);
    },
    removeAttach(f) {
      this.filesToAdd.splice(this.filesToAdd.indexOf(f), 1);
    },
    async markSeen() {
      const ids = _.map(_.filter(this.messages, (i) => i.userId !== this.currentUser.id && !i.isViewed), 'id');
      if (ids.length) {
        await messages.markSeenMessages(ids);
      }
    },
    async send(evt) {
      if (this.isSending || this.myMessage.trim() === '' && this.filesToAdd.length === 0) {
        return;
      }
      if (evt && evt.ctrlKey) {
        this.myMessage += '\n';
        return;
      }
      this.isSending = true;
      await messages.send({
        content: this.myMessage,
        receiver: this.currentUser.id,
        attachments: this.filesToAdd,
        type: 'quick'
      });
      this.filesToAdd = [];
      this.myMessage = '';
      this.isSending = false;
    },
    async onInfinite($state) {
      const el = this.$el.querySelector('.messages');
      const prevH = el.scrollHeight;
      const data = await messages.getAllMessages({
        minId: this.messages.length ? _.min(this.messages, 'id').id : 0,
      });
      if (!data.length) {
        $state.complete();
        return;
      }
      this.messages = [...data, ...this.messages];
      await this.$nextTick();
      el.scrollTop = el.scrollHeight - prevH;
      $state.loaded();
    },
  },
  async created() {
    this.onChatUpdated = async (msgList) => {
      this.messages.push(msgList);
      await this.$nextTick();
      this.scrollToBottom();
    }

    this.onChatsViewed = (arr) => {
      for (let chat of this.messages) {
        if (arr.includes(chat.id))
          chat.isViewed = true;
      }
    }

    this.messages = await messages.getAllMessages({});
    await this.$nextTick();
    this.scrollToBottom();
  },
  mounted() {
    socket.on('chatUpdated', this.onChatUpdated);
    socket.on('chatsViewed', this.onChatsViewed);
  },
  unmounted() {
    socket.off('chatUpdated', this.onChatUpdated);
    socket.off('chatsViewed', this.onChatsViewed);
  },
}
</script>
<style scoped lang="scss">
.chat-container {
  display: flex;
  height: calc(100vh - 110px);
  max-height: 512px;
  margin-top: 30px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(208, 215, 221, 0.7);
}

.messages-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

}

.messages {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  flex-shrink: 10000000;
  background: #fff;
}

.message {
  position: relative;
  word-wrap: break-word;
  margin: 10px 25px;
  line-height: 1.29;
  letter-spacing: -0.2px;
  max-width: 250px;
  width: fit-content;
  padding: 15px;
  border-radius: 8px;
}

.message.my {
  margin-left: auto;
}

.controls {
  display: flex;
  min-height: 20px;
  padding: 22px 0;
  border-top: 1px solid rgba(208, 215, 221, 0.7);
}

.invisible-btn {
  padding: 8px 30px;
  background: transparent;
  color: #b9c5cd;
  border: none;
  font-size: 22px;
}

.message .time {
  font-size: 10px;
  letter-spacing: -0.1px;
  text-align: right;
}

.message .sender {
  display: inline;
  font-weight: bold;
  letter-spacing: -0.2px;
}

textarea.form-control {
  resize: none;
}
</style>
